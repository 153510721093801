import React, { useEffect, useState, useCallback } from "react"
import { connect } from "react-redux"
import { FormGroup, FormCheckbox, Button } from "shards-react"
import { useTranslation } from "react-i18next"
import { Loading } from "../../../components/exceptions/Loading"
import { documentChecklistActions } from ".."
import { modalActions } from "../../modals/_actions"
import { ApplicationDetailsActions } from "../../applicationManager"

import "./List.scss"

function DocumentChecklist({
  id: applicationId,
  documentChecklist,
  readonly,
  dispatch,
  location,
}) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const disabled = !!readonly

  const { state } = location || {}

  const { groupID } = state || {}

  const fetchApplicationChecklist = useCallback(async () => {
    setIsLoading(true)
    await dispatch(
      documentChecklistActions.getApplicationChecklist(applicationId || groupID)
    )
    setIsLoading(false)
  }, [dispatch, applicationId])

  useEffect(() => {
    fetchApplicationChecklist()
  }, [fetchApplicationChecklist])

  const handleChange = async (checklistId, confirmed) => {
    setIsLoading(true)
    await dispatch(
      documentChecklistActions.saveApplicationChecklist({
        applicationId,
        checklistId,
        confirmed: !confirmed,
      })
    )
    setIsLoading(false)
  }

  // Handle modal opening
  const deleteFileModal = (checklistId) => {
    dispatch(
      modalActions.deleteApplicationChecklist({
        applicationId,
        checklistId,
        documentUrl: "",
      })
    )
  }

  const uploadFileModal = (id) => {
    dispatch(modalActions.uploadApplicationChecklist(id, applicationId))
  }

  const previewFileModal = (id, modalTitle, documentUrl) => {
    dispatch(
      modalActions.previewApplicationChecklist(id, modalTitle, documentUrl)
    )
  }

  const downloadFile = (documentUrl) => {
    window.location.href = documentUrl
    return false
  }

  return (
    <>
      <div className="document-checklist">
        <h4 className="document-checklist__section-title">
          {" "}
          {t("module.documentChecklist.view.list.requiredDocs")}{" "}
          {`${documentChecklist.data.length}`}{" "}
        </h4>
        <div className="document-checklist__wrapper">
          {isLoading ? (
            <Loading />
          ) : (
            documentChecklist.data.map((item) => {
              const { id, name, documentUrl, confirmed } = item
              return (
                <div key={id} className="document-checklist__item flex">
                  <div className="document-checklist__title mr-auto">
                    {name}
                  </div>
                  <div className="document-checklist__actions">
                    <Button
                      theme="secondary"
                      className={!documentUrl && "inactive-secondary"}
                      onClick={() => previewFileModal(id, name, documentUrl)}
                    >
                      {t("module.documentChecklist.view.list.preview")}
                    </Button>
                    {documentUrl ? (
                      <Button
                        theme="secondary"
                        onClick={() => downloadFile(documentUrl)}
                      >
                        {t("module.documentChecklist.view.list.download")}
                      </Button>
                    ) : (
                      <Button
                        theme="secondary"
                        onClick={() => uploadFileModal(id)}
                      >
                        {t("module.documentChecklist.view.list.upload")}
                      </Button>
                    )}
                    <Button
                      theme="danger"
                      className={!documentUrl && "inactive-danger"}
                      onClick={() => deleteFileModal(id)}
                    >
                      {t("module.documentChecklist.view.list.delete")}
                    </Button>
                    <FormGroup key={id} className="flex mb-0">
                      <FormCheckbox
                        name={id}
                        onChange={() => {
                          handleChange(id, confirmed)
                        }}
                        checked={confirmed}
                        disabled={disabled}
                      >
                        {t("module.documentChecklist.view.list.confirmed")}
                      </FormCheckbox>
                    </FormGroup>
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>

      <ApplicationDetailsActions id={applicationId} saveDisabled={true} />
    </>
  )
}

const mapStateToProps = (state) => {
  const { documentChecklist } = state
  return { documentChecklist }
}

const connectedDocumentChecklist = connect(mapStateToProps)(DocumentChecklist)
export { connectedDocumentChecklist as DocumentChecklist }
