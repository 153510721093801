import React, { useState, useEffect, Fragment } from "react"
import { isEmpty, first } from "lodash"
import { connect, useSelector } from "react-redux"
import TooltipTrigger from "react-popper-tooltip"
import { withTranslation } from "react-i18next"
import { viewProfile } from "../../../utils/templates"
import { userRoleConstants, hasAuthority } from "../../../_helpers"
import { modalActions } from "../../modals"
import "react-popper-tooltip/dist/styles.css"
import { workflowActions } from "../../workflow"
import { applicationActions } from "../"
import { LoadingView } from "../../../views"

const ApplicationTableRowsActions = (props) => {
  const {
    id,
    applicationsList,
    xformId,
    xformversion,
    t,
    hasWorkflow,
    workflow: { assigneesForward, assigneesBack },
    users,
    status,
    stage,
    surveyStatus,
    authentication: {
      profile: {
        username: loggedInUser,
        canDisburse,
        mfi: { id: mfiId },
      },
    },
    dispatch,
    fromAppDetails,
    suppressDetails,
    approvalUsers,
  } = props
  const { menus = [] } = useSelector((store) => store.mfis)

  const [fullSubmission, setFullsubmission] = useState({})
  const [applicationFlowUsers, setApplicationFlowUsers] = useState([])
  const [applicationBackFlowUsers, setApplicationBackFlowUsers] = useState([])

  const applications = useSelector((state) => state.applications)
  const { loading } = applications

  useEffect(() => {
    const newFullSubmission = first(
      applicationsList.filter((item) => Number(item.id) === Number(id))
    )
    setFullsubmission(newFullSubmission ? newFullSubmission : {})
  }, [applicationsList, id])

  useEffect(() => {
    setApplicationFlowUsers(assigneesForward)
  }, [assigneesForward])

  useEffect(() => {
    setApplicationBackFlowUsers(assigneesBack)
  }, [assigneesBack])

  const handlePageChange = (data) => {
    dispatch(applicationActions.applicationClicked(data))
  }

  const showViewProfile =
    menus.length > 0 &&
    menus.find(
      (menu) => menu.componentName === viewProfile.label && menu.enabled
    ) !== undefined

  const assignForm = (assigneeUsername, target) => {
    dispatch(
      modalActions.assign({
        assigneeUsername,
        applicationId: id,
        fullSubmission,
        limit: "",
        target,
        suppressDetails,
      })
    )
  }

  const approveApplication = () => {
    dispatch(
      modalActions.approve({
        fullSubmission,
        approve: true,
        applicationId: id,
        limit: "",
      })
    )
  }

  const declineApplication = () => {
    dispatch(
      modalActions.approve({
        fullSubmission,
        decline: true,
        applicationId: id,
        limit: "",
      })
    )
  }

  const disburseApplication = () => {
    dispatch(
      modalActions.disburse({
        disburseAction: true,
        applicationId: id,
      })
    )
  }

  const refuseApplication = () => {
    dispatch(
      modalActions.refuse({
        disburseAction: false,
        applicationId: id,
      })
    )
  }

  const getAssignees = (role) => {
    if (hasWorkflow) {
      const foundUsers =
        applicationFlowUsers &&
        applicationFlowUsers.filter(
          (user) => Number(user.applicationID) === Number(id)
        )
      return foundUsers
    }

    return users.items
  }

  const getBackAssignees = () => {
    if (hasWorkflow) {
      const foundUsers =
        applicationBackFlowUsers &&
        applicationBackFlowUsers.filter(
          (user) => Number(user.applicationID) === Number(id)
        )
      return foundUsers
    } else if (hasAuthority(userRoleConstants.MFI_LOAN_OFFICER)) {
      return false
    }

    return users.items
  }

  const approvePayment = () => {
    dispatch(
      modalActions.approvePaymentAction({
        disburseAction: false,
        applicationId: id,
      })
    )
  }

  const declinePayment = () => {
    dispatch(
      modalActions.declinePaymentAction({
        disburseAction: false,
        applicationId: id,
      })
    )
  }

  const isAssignable = () => {
    let retVal = true
    const { subRoleStageFinal, currentUser: stageOwner } = fullSubmission
    if (subRoleStageFinal) {
      retVal = false
    }
    if (stageOwner !== loggedInUser) {
      retVal = false
    }

    if (status.toUpperCase() !== "REVIEW") {
      retVal = false
    }

    if (hasWorkflow) {
      return retVal
    }

    if (
      stage === userRoleConstants.MFI_LOAN_OFFICER &&
      !hasAuthority(userRoleConstants.MFI_LOAN_OFFICER)
    ) {
      retVal = false
    }

    if (
      stage === userRoleConstants.MFI_CREDIT_ANALYST &&
      hasAuthority(userRoleConstants.MFI_LOAN_OFFICER)
    ) {
      retVal = false
    }

    if (
      stage === userRoleConstants.MFI_CREDIT_ANALYST &&
      hasAuthority(userRoleConstants.MFI_SUPERVISOR)
    ) {
      retVal = false
    }

    if (
      stage === userRoleConstants.MFI_LOAN_OFFICER &&
      hasAuthority(userRoleConstants.MFI_SUPERVISOR)
    ) {
      retVal = true
    }

    if (
      stage === userRoleConstants.MFI_SUPERVISOR &&
      hasAuthority(userRoleConstants.MFI_LOAN_OFFICER)
    ) {
      retVal = false
    }

    return retVal
  }

  const canDecline = () => {
    let retVal = false
    const { currentUser: stageOwner, subRoleStageFinal } = fullSubmission

    if (subRoleStageFinal) {
      retVal = true
    }

    if (stageOwner !== loggedInUser) {
      retVal = false
    }

    if (
      status.toUpperCase() === "APPROVED" ||
      status.toUpperCase() === "DECLINED" ||
      status.toUpperCase() === "DISBURSED" ||
      status.toUpperCase() === "REFUSED"
    ) {
      retVal = false
    }

    if (hasWorkflow) {
      return retVal
    }
    return retVal
  }

  const canApprove = () => {
    let retVal = false
    const { subRoleStageFinal, currentUser: stageOwner } = fullSubmission

    if (hasWorkflow) {
      retVal = subRoleStageFinal
    }

    if (stageOwner !== loggedInUser) {
      retVal = false
    }

    if (
      status.toUpperCase() === "APPROVED" ||
      status.toUpperCase() === "DECLINED" ||
      status.toUpperCase() === "DISBURSED" ||
      status.toUpperCase() === "REFUSED" ||
      surveyStatus?.toUpperCase() === "INCOMPLETE"
    ) {
      retVal = false
    }

    if (hasWorkflow) {
      return retVal
    }

    if (status.toUpperCase() === "REVIEW") {
      retVal = true
    }

    if (
      stage === userRoleConstants.MFI_LOAN_OFFICER &&
      !hasAuthority(userRoleConstants.MFI_LOAN_OFFICER)
    ) {
      retVal = false
    }

    return retVal
  }

  const canViewScore = () => {
    let retVal = false

    if (
      stage === userRoleConstants.MFI_CREDIT_ANALYST ||
      status.toUpperCase() === "APPROVED" ||
      status.toUpperCase() === "DECLINED" ||
      status.toUpperCase() === "DISBURSED" ||
      status.toUpperCase() === "REFUSED"
    ) {
      retVal = true
    }

    return retVal
  }

  const showAssignToandBack = () => {
    let retVal = true
    const { currentUser: stageOwner } = fullSubmission
    if (
      status.toUpperCase() === "APPROVED" ||
      status.toUpperCase() === "DECLINED" ||
      status.toUpperCase() === "DISBURSED" ||
      status.toUpperCase() === "REFUSED" ||
      (fromAppDetails && !approvalUsers?.includes(loggedInUser)) ||
      (!fromAppDetails && stageOwner !== loggedInUser)
    ) {
      retVal = false
    }

    return retVal
  }

  /**
   * Edit links
   * Visible to MFI users only
   */
  const applicationEditToolsView = () => {
    if (!isAssignable() || fromAppDetails) {
      return false
    }

    if (!hasAuthority(userRoleConstants.MFI_LOAN_OFFICER)) {
      return false
    }

    return (
      <Fragment>
        <div className="dropdown-item">
          <div>
            <button
              onClick={(e) =>
                handlePageChange({
                  page: "EditApplication",
                  history: false,
                  id,
                  xformId,
                  xformversion,
                  loggedInUser,
                })
              }
            >
              {t(
                "module.applicationManager.component.applicationTableRowsActions.edit"
              )}
            </button>
          </div>
        </div>
        <div className="dropdown-item">
          <div>
            <button
              onClick={(e) =>
                handlePageChange({
                  page: "EditApplication",
                  tab: "checklist",
                  history: false,
                  id,
                  xformId,
                  xformversion,
                  loggedInUser,
                })
              }
            >
              {t(
                "module.applicationManager.component.applicationTableRowsActions.checklist"
              )}
            </button>
          </div>
        </div>
      </Fragment>
    )
  }

  /**
   * View links
   * Visible to MFI users only
   */
  const applicationViewToolsView = () => {
    if (fromAppDetails) {
      return null
    }
    return (
      <Fragment>
        <div className="dropdown-item">
          <div>
            <button
              onClick={(e) =>
                handlePageChange({
                  page: "ViewApplication",
                  tab: "checklist",
                  history: false,
                  id,
                  xformId,
                  xformversion,
                  loggedInUser,
                })
              }
            >
              {t(
                "module.applicationManager.component.applicationTableRowsActions.view"
              )}
            </button>
          </div>
        </div>
      </Fragment>
    )
  }

  /**
   * View links
   * Visible to MFI users only
   */
  const applicationViewProfileView = () => {
    if (fromAppDetails || !showViewProfile) {
      return null
    }
    return (
      <Fragment>
        <div className="dropdown-item">
          <div>
            <button
              onClick={(e) =>
                handlePageChange({
                  page: "ViewApplicationProfile",
                  tab: "checklist",
                  history: false,
                  id,
                  xformId,
                  xformversion,
                  loggedInUser,
                  mfiId,
                })
              }
            >
              {t(
                "module.applicationManager.component.applicationTableRowsActions.viewprofile"
              )}
            </button>
          </div>
        </div>
      </Fragment>
    )
  }

  /**
   * View Credit Score
   * Visible to MFI_CREDIT_ANALYST and MFI_SUPERVISOR only
   */
  const viewScoreView = () => {
    if (!canViewScore() || fromAppDetails) {
      return false
    }

    return (
      <div className="dropdown-item report">
        <div>
          <button
            onClick={(e) =>
              handlePageChange({
                page: "ViewApplication",
                tab: "creditscore",
                history: false,
                id,
                xformId,
                xformversion,
                loggedInUser,
              })
            }
          >
            {t(
              "module.applicationManager.component.applicationTableRowsActions.viewScore"
            )}
          </button>
        </div>
      </div>
    )
  }

  /**
   * Approve Application
   * Visible to MFI_CREDIT_ANALYST and MFI_SUPERVISOR only
   */
  const approveApplicationView = () => {
    if (!canApprove() || fromAppDetails) {
      return false
    }

    return (
      <div className="dropdown-item">
        <div
          onClick={() => {
            approveApplication()
          }}
        >
          {t(
            "module.applicationManager.component.applicationTableRowsActions.approve"
          )}
        </div>
      </div>
    )
  }

  /**
   * Decline Application
   * Visible to MFI_CREDIT_ANALYST and MFI_SUPERVISOR only
   */
  const declineApplicationView = () => {
    if (!canDecline() || fromAppDetails) {
      return false
    }
    return (
      <div className="dropdown-item">
        <div
          onClick={() => {
            declineApplication()
          }}
        >
          {t(
            "module.applicationManager.component.applicationTableRowsActions.decline"
          )}
        </div>
      </div>
    )
  }

  /**
   * Disburse Application
   * Visible only if application has the status approved
   */
  const disburse = () => {
    if (status.toUpperCase() !== "APPROVED" || !canDisburse || fromAppDetails) {
      return false
    }
    return (
      <div className="dropdown-item">
        <div
          onClick={() => {
            disburseApplication()
          }}
        >
          {t("applicationDisburse.disburse")}
        </div>
      </div>
    )
  }

  /**
   * Refuse Application
   * Visible only if application has the status approved
   */
  const refuse = () => {
    if (status.toUpperCase() !== "APPROVED" || !canDisburse || fromAppDetails) {
      return false
    }
    return (
      <div className="dropdown-item">
        <div
          onClick={() => {
            refuseApplication()
          }}
        >
          {t("applicationDisburse.refuse")}
        </div>
      </div>
    )
  }

  const canApprovePayment = () => {
    if (status.toUpperCase() !== "PAYMENT") {
      return false
    }
    return (
      <div className="dropdown-item">
        <div onClick={approvePayment}>
          {t("Approve Payment")} {/* Replace with appropriate translation */}
        </div>
      </div>
    )
  }

  const canDeclinePayment = () => {
    if (status.toUpperCase() !== "PAYMENT") {
      return false
    }
    return (
      <div className="dropdown-item">
        <div onClick={declinePayment}>
          {t("Decline Payment")} {/* Replace with appropriate translation */}
        </div>
      </div>
    )
  }

  const toggleAssign = () => {
    if (
      !hasWorkflow ||
      status.toUpperCase() === "APPROVED" ||
      status.toUpperCase() === "DECLINED"
    ) {
      return
    }
    dispatch(workflowActions.getAssignees(id))
  }

  /**
   * Assign to Credit analysts
   * Visible to MFI_LOAN_OFFICER and MFI_SUPERVISOR only
   */
  const assignToUserView = (title) => {
    const assignableUsers = getAssignees()
    if (!showAssignToandBack()) {
      return
    }

    return (
      <Fragment>
        <div className={`dropdown-item relative assign`}>
          <div
            className="dropdown-toggle toggle-right"
            onMouseEnter={() => toggleAssign()}
          >
            {!fromAppDetails ? title : "Approve"}
          </div>
          <div className="dropdown-submenu">
            {!isEmpty(assignableUsers) ? (
              assignableUsers.map((user) => {
                // eslint-disable-next-line no-shadow
                const { username: assigneeUsername } = user
                return (
                  <div
                    className="dropdown-item username"
                    key={user.id}
                    onClick={() => {
                      assignForm(assigneeUsername, user.roles[0])
                    }}
                  >{`${assigneeUsername}  (${user.roles[0]})`}</div>
                )
              })
            ) : (
              <div className="assign-empty-message">
                {t("module.applications.noOneToAssign")}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }

  /**
   * Assign back
   * Visible to MFI_LOAN_OFFICER and MFI_SUPERVISOR only
   */
  const assignBackUserView = (title) => {
    const assignableUsers = getBackAssignees()
    if (!showAssignToandBack() || fromAppDetails) {
      return
    }

    return (
      <Fragment>
        <div className={`dropdown-item relative assign`}>
          <div
            className="dropdown-toggle toggle-right"
            onMouseEnter={() => toggleAssign()}
          >
            {title}
          </div>
          <div className="dropdown-submenu">
            {!isEmpty(assignableUsers) ? (
              assignableUsers.map((user) => {
                // eslint-disable-next-line no-shadow
                const { username: assigneeUsername } = user
                return (
                  <div
                    className="dropdown-item username"
                    key={user.id}
                    onClick={() => {
                      assignForm(assigneeUsername, user.roles[0])
                    }}
                  >{`${assigneeUsername}  (${user.roles[0]})`}</div>
                )
              })
            ) : (
              <div className="assign-empty-message">
                {t("module.applications.noOneToAssign")}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }

  const TooltipContent = (
    <Fragment>
      {applicationEditToolsView()}
      {applicationViewToolsView()}
      {applicationViewProfileView()}
      {assignToUserView(t("module.applications.assignTo"))}
      {assignBackUserView(t("module.applications.assignBack"))}
      {viewScoreView()}
      {disburse()}
      {refuse()}
      {approveApplicationView()}
      {declineApplicationView()}
      {canApprovePayment()}
      {canDeclinePayment()}
    </Fragment>
  )

  // Hacky way to check if React Fragment chldren are empty
  const contentEmpty = TooltipContent.props.children.every(
    (item) => item === false
  )

  const Trigger = ({ getTriggerProps, triggerRef }) => (
    <button
      {...getTriggerProps({
        ref: triggerRef,
        className: "trigger dropdown-toggle btn btn-primary mr-2",
      })}
    >
      {t(
        "module.applicationManager.component.applicationTableRowsActions.actions"
      )}
    </button>
  )

  const NoActionsTrigger = ({ getTriggerProps, triggerRef }) => (
    <button
      {...getTriggerProps({
        ref: triggerRef,
        className:
          "trigger dropdown-toggle btn btn-primary btn-disabled dropdown-toggle__no-caret",
      })}
    >
      {t("module.applications.noActions")}
    </button>
  )

  const Tooltip = ({
    getTooltipProps,
    getArrowProps,
    tooltipRef,
    arrowRef,
    placement,
  }) => (
    <div
      {...getTooltipProps({
        ref: tooltipRef,
        className: "tooltip-container table-actions",
      })}
    >
      <div
        {...getArrowProps({
          ref: arrowRef,
          "data-placement": placement,
          className: "tooltip-arrow",
        })}
      />
      <div className="tooltip-body">
        <div className="dropdown dropdown-right">{TooltipContent}</div>
      </div>
    </div>
  )

  const NoContentTooltip = ({
    getTooltipProps,
    getArrowProps,
    tooltipRef,
    arrowRef,
    placement,
  }) => (
    <div
      {...getTooltipProps({
        ref: tooltipRef,
        className: "tooltip-container table-actions",
      })}
    >
      <div
        {...getArrowProps({
          ref: arrowRef,
          "data-placement": placement,
        })}
      />
      <div className="tooltip-body">{NoActionsTrigger}</div>
    </div>
  )

  if (
    hasAuthority(userRoleConstants.FA_ANALYST) ||
    hasAuthority(userRoleConstants.FA_ADMIN)
  ) {
    return null
  }

  if (loading) {
    return <LoadingView />
  }

  return (
    <Fragment>
      {!contentEmpty && (
        <TooltipTrigger placement="right" trigger="click" tooltip={Tooltip}>
          {Trigger}
        </TooltipTrigger>
      )}
      {contentEmpty && (
        <TooltipTrigger
          placement="right"
          trigger="click"
          tooltip={NoContentTooltip}
        >
          {NoActionsTrigger}
        </TooltipTrigger>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  const { applications, workflow, users, authentication } = state
  const { items: applicationsList } = applications

  return {
    applicationsList,
    workflow,
    users,
    authentication,
  }
}

const connectedtableRowsActions = withTranslation()(
  connect(mapStateToProps)(ApplicationTableRowsActions)
)
export { connectedtableRowsActions as ApplicationTableRowsActions }
