import React from 'react';
import { useDispatch } from 'react-redux';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import Header from '../../../components/widget/widgetTitle';
import { modalActions } from '../../modals/_actions';

export function PreviewApplicationChecklist({ modalID, data }) {
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header title={data.modalTitle} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="site-modal-body">
                <img className="preview_file" alt={data.modalTitle} src={data.documentUrl} />
            </div>
        </div>
    );
}
