import React from "react"
import { Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { Map, GoogleApiWrapper } from "google-maps-react"
import styles from "./Editor.styles"
import "./Editor.scss"

const GpsShape = ({ kuid, type, label, value, classes, google }) => {
  const getCenterCoords = (coords) => {
    const x = coords.map((xy) => xy[0])
    const y = coords.map((xy) => xy[1])
    const cx = (Math.min(...x) + Math.max(...x)) / 2
    const cy = (Math.min(...y) + Math.max(...y)) / 2
    return [cx, cy]
  }

  const geoShapeCoords = value.split(";")
  const coords = geoShapeCoords.map((geoPoint) => [
    geoPoint.trim().split(" ")[0],
    geoPoint.trim().split(" ")[1],
  ])
  const centerCoords = getCenterCoords(coords)

  const polygonCoords = coords.map((cd) => ({
    lat: Number(cd[0]),
    lng: Number(cd[1]),
  }))

  const onMapReady = (mapProps, map) => {
    map.setCenter({ lat: centerCoords[0], lng: centerCoords[1] })
    // Add a polygon to the map
    const polygon = new window.google.maps.Polygon({
      path: polygonCoords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    })
    polygon.setMap(map)
  }

  return (
    <div className={classes.gpsContainer}>
      <div key={kuid} className={classes.gpsSection}>
        <Typography className={classes.gpsSubtitleShape}>{type}</Typography>
        <div className="form-input-note">{label[0]}</div>
      </div>
      <div className={classes.mapContainer}>
        <Map
          google={google}
          onReady={onMapReady}
          className={"custom-map"}
          zoom={17}
          initialCenter={{ lat: 0, lng: 0 }}
        ></Map>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_API_KEY,
})(withStyles(styles)(GpsShape))
